import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
// import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

const appRoutes: Routes = [
    // {
    //     path        : 'apps',
    //     loadChildren: './main/apps/apps.module#AppsModule'
    // },
    {
        path        : 'dashboard',
        loadChildren: './main/dashboard/project/project.module#ProjectDashboardModule',
        // canLoad: [AuthguardGuard],

    },
    {
        path        : 'auth',
        loadChildren: './main/pages/pages.module#PagesModule',
    },
    {
        path        : 'timesheets',
        loadChildren: './main/timesheets/timesheets.module#TimeSheetModule'
    },
    {
        path        : 'settings',
        loadChildren: './main/settings/settings.module#SettingsModule'
    },
    {
        path        : 'edit-profile',
        loadChildren: './main/edit-profile/edit-profile.module#EditProfileModule'
    },
    {
        path        : 'ui',
        loadChildren: './main/ui/ui.module#UIModule'
    },
    // {
    //     path        : 'documentation',
    //     loadChildren: './main/documentation/documentation.module#DocumentationModule'
    // },
    // {
    //     path        : 'angular-material-elements',
    //     loadChildren: './main/angular-material-elements/angular-material-elements.module#AngularMaterialElementsModule'
    // },

    // Main Routes

    {path: '',redirectTo: 'auth',pathMatch: 'full'},
    {path: 'login',redirectTo: 'auth/login',pathMatch: 'full'},
    {path: 'forgot-password',redirectTo: 'auth/forgot-password',pathMatch: 'full'},
    {path: 'lock',redirectTo: 'auth/lock',pathMatch: 'full'},
    {path: 'reset-password',redirectTo: 'auth/reset-password',pathMatch: 'full'},

    {
        path      : '**',
        redirectTo: 'dashboard'
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay             : 0,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        NgxMaterialTimepickerModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,

        // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        // AppStoreModule
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
